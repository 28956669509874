import { template as template_2db4e00512ea48b5b2df0b9c61d85c08 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2db4e00512ea48b5b2df0b9c61d85c08(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
