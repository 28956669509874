import { template as template_193f42aebf164075888216238e1f6089 } from "@ember/template-compiler";
const FKLabel = template_193f42aebf164075888216238e1f6089(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
